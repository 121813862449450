/**
 * External dependencies
 */
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

/**
 * Internal dependencies
 */
import {
	Button,
	CaseStudyHero,
	NavBarColorSwitcher,
	Spacer,
	Summary,
	TabletsBox,
	WrapperBody,
} from '../components';
import ContentColumns from '../components/ContentColumns';
import Cta from '../components/Cta';

type CaseStudyProps = {
	mdx: {
		frontmatter: {
			title: string;
		};
		body: string;
	};
};

export default function PageTemplate(props: PageProps<CaseStudyProps>) {
	const resultsStats: Record<string, string> = {};

	props.pageContext.frontmatter?.results_stats?.map((result, i) => {
		if (i % 2 === 0) {
			resultsStats[result] =
				props.pageContext.frontmatter?.results_stats[i + 1];
		}

		return null;
	});

	const Body = () => (
		<>
			<WrapperBody withSpace={false}>
				<MDXRenderer>{props.data.mdx.body}</MDXRenderer>
			</WrapperBody>
			<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
			<Button to="/our-work">Back to the list</Button>
		</>
	);

	const summaryItems: Record<string, string | Array<string>> = {};

	if (props.pageContext.frontmatter.partnership_goals) {
		summaryItems['Partnership goal'] =
			props.pageContext.frontmatter.partnership_goals;
	}

	if (props.pageContext.frontmatter.technology) {
		summaryItems['Technology'] = props.pageContext.frontmatter.technology;
	}

	if (props.pageContext.frontmatter.results) {
		summaryItems['Results'] = props.pageContext.frontmatter.results;
	}

	return (
		<>
			<CaseStudyHero
				title={props.data.mdx.frontmatter.title}
				params={{
					Contract: props.pageContext.frontmatter.contract,
					Country: props.pageContext.frontmatter.country,
					Industry: props.pageContext.frontmatter.tagline,
					Timeline: props.pageContext.frontmatter.timeline,
				}}
			/>
			<NavBarColorSwitcher color="dark">
				<div className="single-case-study">
					<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
					{props.pageContext.frontmatter.partnership_goals ||
					props.pageContext.frontmatter.technology ||
					props.pageContext.frontmatter.results ||
					props.pageContext.frontmatter.results_stats ||
					props.pageContext.frontmatter.worked_on ? (
						<ContentColumns
							reverseOnMobile={true}
							columnContent={
								<>
									{props.pageContext.frontmatter
										.worked_on && (
										<TabletsBox
											title="We worked on"
											items={
												props.pageContext.frontmatter
													.worked_on
											}
										/>
									)}
									{(props.pageContext.frontmatter
										.partnership_goals ||
										props.pageContext.frontmatter
											.technology ||
										props.pageContext.frontmatter.results ||
										props.pageContext.frontmatter
											.results_stats) && (
										<Summary
											items={summaryItems}
											otherItems={
												props.pageContext.frontmatter
													?.results_stats
													? resultsStats
													: {}
											}
										/>
									)}
								</>
							}
						>
							<Body />
						</ContentColumns>
					) : (
						<Body />
					)}
					<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
					<Cta
						title="Take partnership to the next level"
						subtitle="Some clients stay with us for over 4 years. With our experienced team,  we’re ready to assist you with full-cycle development"
						buttonText="Get in touch"
						to="/contact"
					/>
					<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
				</div>
			</NavBarColorSwitcher>
		</>
	);
}

export const pageQuery = graphql`
	query CASE_STUDY($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			frontmatter {
				slug
				title
				image {
					childImageSharp {
						gatsbyImageData
					}
				}
				image_title
				tags
				date
				tagline
				contract
				country
				timeline
				worked_on
				partnership_goals
				technology
				results
				results_stats
			}
		}
	}
`;
