import { FC, PropsWithChildren, ReactNode } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import classnames from 'classnames';

type ContentColumnsProps = PropsWithChildren<{
	columnContent: ReactNode;
	reverseOnMobile?: boolean;
}>;

const ContentColumns: FC<ContentColumnsProps> = ({
	children,
	columnContent,
	reverseOnMobile = false,
}) => (
	<div
		className={classnames(
			'columns-content',
			reverseOnMobile && 'reverse-mobile'
		)}
	>
		<div className="columns-content-left">{children}</div>
		<div className="columns-content-right">{columnContent}</div>
	</div>
);

export default ContentColumns;
